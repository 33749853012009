import { SeverityLevel } from '@sentry/react'
import { Session } from '@chilipiper/api-type-def'
import { ApiConfig } from '@chilipiper/api-client/src/http-client'
import { ResponsePromise } from 'ky'

export interface ApiError {
  error: string
  type: string
}

/*
  IMPORTANT: Generated TokenResponse and TokenForm are NOT correct and should NOT be used. Below is the correct type returned by API. I am not sure if the issue is with how the API was written or if there is a bug in the code generator.
*/
export type PatchedTokenResponse =
  | ({
      type: 'RefreshTokenInBody'
    } & { 'jwt-access': string; 'jwt-refresh': string })
  | ({
      type: 'RefreshTokenInCookie'
    } & { 'jwt-access': string })

export interface PatchedTokenForm {
  authCode: string
  tenantId: string
}

export interface BasicAuthParams {
  clientId: string
  clientSecret?: string
}

export interface BasicAuthRefresh extends BasicAuthParams {
  'jwt-refresh'?: string
}

export interface BasicAuthLogin extends BasicAuthParams, PatchedTokenForm {}

export type SessionResponse = Session | ApiError

export interface ScopeContext {
  name: string
  values: Record<string, string>
}

export interface ScopeTag {
  name: string
  value: string
}

export interface ErrorCallbackOptions {
  context?: ScopeContext
  level?: SeverityLevel
  mute?: boolean
  tag?: ScopeTag
  transactionName?: string
}

export type PageParams = {
  page?: number
  size?: number
}

export type JwtLogin = string | ApiConfig | BasicAuthLogin | BasicAuthRefresh

export const isBasicAuthLogin = (options?: JwtLogin): options is BasicAuthLogin => {
  return options !== undefined && typeof options !== 'string' && 'clientId' in options
}

export const isBasicAuthRefresh = (options?: JwtLogin): options is BasicAuthRefresh => {
  return isBasicAuthLogin(options) && 'jwt-refresh' in options
}

export interface JwtService<LoginResponse, SessionData = undefined> {
  getSessionData: () => SessionData
  getToken: () => string
  isAuthenticated: () => boolean
  login: (options?: JwtLogin) => Promise<LoginResponse>
  logout: () => Promise<void> | void
  refresh: (basicAuth?: BasicAuthRefresh) => Promise<LoginResponse>
  setToken: (token: string) => void
}

type ErrorWithResponsePromise = Error & { response: ResponsePromise }

export const isErrorWithResponsePromise = (error: unknown): error is ErrorWithResponsePromise => {
  return typeof error === 'object' && error !== null && 'response' in error
}
