import { nanoid } from 'nanoid'
import { ApiConfig } from '@chilipiper/api-client/src/http-client'
import { jwtApiV2, redirectToLogin as v2RedirectToLogin } from '@chilipiper/service/src/jwtApiV2'
import { isFireAuth } from '@chilipiper/rollout'
import { jwtApiV2WithV1Fallback } from './jwtApiV2WithV1Fallback'
import { guestJwt } from './guestJwt'

const cameFromAuthService = (url: RequestInfo | URL) => url.toString().includes('/fire-auth')

export const sanitizeErrors = async (response: Response) => {
  if (!response.ok) {
    try {
      await response.clone().json()
      return response
    } catch {
      throw {
        statusText: response.statusText,
        status: response.status,
      }
    }
  }
  return response
}

type AuthType = 'public' | 'guest' | 'auth'

export const getAuthType = (input: RequestInfo | URL): AuthType => {
  if (isPublicRequest(input)) {
    return 'public'
  }
  if (isGuestRequest(input)) {
    return 'guest'
  }
  return 'auth'
}

const publicPathRegExp = /\/api\/.+\/v\d+\/public\//

const isPublicRequest = (input: RequestInfo | URL) => publicPathRegExp.test(getPathname(input))

const guestPathRegExp = /\/api\/.+\/v\d+\/guest-external\//

export const isGuestRequest = (input: RequestInfo | URL) => guestPathRegExp.test(getPathname(input))
const getPathname = (input: RequestInfo | URL) => {
  if (typeof input === 'string') {
    return input
  }
  if (input instanceof Request) {
    return new URL(input.url).pathname
  }
  return input.pathname
}

export const setupAuthHeadersV2 = async (init: RequestInit, authType: AuthType) => {
  init.headers = {
    ...(init.headers as Record<string, string>),
    'x-request-id': nanoid(),
  }

  if (authType === 'auth') {
    const token = jwtApiV2.getToken()
    if (!init.headers.authorization && token) {
      init.headers.authorization = `Bearer ${token}`
    }
  } else if (authType === 'guest') {
    if (!guestJwt.isAuthenticated()) {
      await guestJwt.login()
    }
    const token = guestJwt.getToken()
    if (!init.headers.authorization && token) {
      init.headers.authorization = `Bearer ${token}`
    }
  } else {
    delete init.headers.authorization
  }
}

export const getApiV2Config = (): ApiConfig => {
  return {
    customFetch: async (input, init = {}) => {
      const authType = getAuthType(input)
      await setupAuthHeadersV2(init, authType)
      return sanitizeErrors(await refreshTokenMiddlewareV2(input, init))
    },
  }
}

export const refreshTokenMiddlewareV2 = async (input: RequestInfo | URL, init: RequestInit) => {
  const response = await fetch(input, init)
  try {
    const authType = getAuthType(input)
    if (![401, 403].includes(response.status) || authType === 'public') {
      return response
    }

    if (authType === 'auth') {
      if (cameFromAuthService(input)) {
        v2RedirectToLogin()
        return response
      }
      await jwtApiV2.login().catch(err => {
        if (err instanceof Response && [401, 403].includes(err.status)) {
          v2RedirectToLogin()
        }
      })
      init.headers = {
        ...init.headers,
        authorization: `Bearer ${jwtApiV2.getToken()}`,
      }
    } else if (authType === 'guest') {
      await guestJwt.refresh()
      init.headers = {
        ...init.headers,
        authorization: `Bearer ${guestJwt.getToken()}`,
      }
    }

    return fetch(input, init)
  } catch (err) {
    console.error(err)
    return response
  }
}

export const getApiV1Config = (): ApiConfig => ({
  customFetch: async (input, init: RequestInit = {}) => {
    init.headers = {
      ...(init.headers as Record<string, string>),
      'x-request-id': nanoid(),
    }

    const token = jwtApiV2WithV1Fallback.getToken()
    if (getAuthType(input) === 'auth' && token) {
      init.headers.authorization = `Bearer ${token}`
    }

    return sanitizeErrors(await refreshTokenMiddlewareV1(input, init))
  },
})

const v1RedirectToLogin = () => {
  const auth_path = `/login.html`
  window.location.href = `${window.location.origin}${auth_path}`
}

const refreshTokenMiddlewareV1 = async (input: RequestInfo | URL, init: RequestInit) => {
  const response = await fetch(input, init)
  try {
    if ([401, 403].includes(response.status)) {
      // no need to check cameFromAuthService as in v1 we never use auth service v2
      await jwtApiV2WithV1Fallback.refresh().catch(() => {
        v1RedirectToLogin()
      })

      if (getAuthType(input) === 'auth') {
        init.headers = {
          ...init.headers,
          authorization: `Bearer ${jwtApiV2WithV1Fallback.getToken()}`,
        }
      }
      return fetch(input, init)
    }
    return response
  } catch (err) {
    console.error(err)
    return response
  }
}

export const getApiConfig = (forceLegacyAuth?: boolean) =>
  isFireAuth() && !forceLegacyAuth ? getApiV2Config() : getApiV1Config()
